import React, { useState, useContext } from "react";
import BgVideo from "../assets/bkg/abstract.mp4";

import "../App.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { InstanceContext } from "../context/InstanceContext";
import { useEffect } from "react";
import UnListedModel from "../Components/UnListedModel";
import {AiOutlineCopy} from 'react-icons/ai';
import { toast } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';


const List = () => {
  
  const [formInput, setFormInput] = useState([{
    price: "",
    amount: "",
  }]);

  const FormHandler = (e) => {
    // setFormInput([...formInput,{price:"",amount:""}])
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };


  //----USECONTEXT
  const { unListedNFT, unlisted, address, isOwner, getBalance, userBalance } = useContext(InstanceContext)
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);

  //---USEEFFECT
  useEffect(() => {
    unListedNFT()
    getBalance()
  }, [address])

  //---open model
  const openModel = (item) => 
  {
    setData(item)
    setShowModal(true)
  }

  const copyClip = async (address,id) => 
  {
    await navigator.clipboard.writeText(`${address}/${id}`)
    toast.success("copied to clipboard")
  }

  return (
    <>
      <Navbar />
      <div className="  min-h-screen py-20 ">
        <video className="market-video-bg" src={BgVideo} autoPlay muted loop />
        <div className="flex justify-center w-full mx-auto mt-6 lg:w-8/12 p-4 pb-4">
            <div className="bg-[#373C44] w-full pb-5 pt-5 px-4 rounded-xl border-2 bg-transparent border-[#660000] bg-opacity-90">
              <div className="flex justify-between ">
                <label className="text-lg text-gray-400">
                  IP Coins
                </label>
                <label className="text-lg text-gray-400">{userBalance}</label>
              </div>
            </div>
          </div>

        <div className="border    border-[#660000] mx-8 md:mx-12 lg:mx-20 mb-20 mt-10 rounded-3xl">
          
       
          
          <div className="flex justify-center mb-2">
            <div className="bg-[#660000] border-2  border-[#660000]  text-[#B87333] test-3xl font-semibold tracking-wide pt-4 pb-3 px-12 rounded-full -mt-8 mb-8">
              Profile
            </div>
          </div>
          {/* grid */}
          <div className="p-2  grid sm:grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-3 gap-1 ">
            {
              unlisted.length > 0 ?
              unlisted.map((item) =>
                <div className="text-white mb-8  mx-4 shadow-lg border border-[#660000]  p-4  rounded-xl">
                  <img className="w-full lg:h-[300px] md:h-[300px] sm:h-[350px] rounded-xl" src={item.image_url} alt="" />
                  <div className="p-2">
                    <h1 className="text-md font-semibold">{item.name}</h1>
                  </div>

                  <div className="flex justify-start px-2">
                    <span className="inline-block text-white  rounded-full text-sm font-semibold mb-1 mr-2">
                      Quantity:
                    </span>
                    <span className="inline-block text-white rounded-full text-sm font-semibold mb-1">
                      {item.quantity}
                    </span>

                  </div>

                  <div className="flex justify-start px-2">
                    <span className="inline-block text-white  rounded-full text-sm font-semibold mb-1 mr-2">
                      Owner:
                    </span>
                    <span className="inline-block text-white rounded-full text-sm font-semibold mb-1">
                    {item.wallet.slice(0,6)}...{item.wallet.slice(36)}
                    </span>

                  </div>

                  <div className="flex justify-between items-center px-2">
                    <div>
                      <span className="inline-block text-white  rounded-full text-sm font-semibold mb-1 mr-2">
                        NFT Address:
                      </span>
                      <CopyToClipboard text={`${process.env.REACT_APP_NFT_CONTRACT}/${item.tokenId}`}
                             onCopy={() => toast.success("Copied to Clipboard")}>
                      <span className="inline-block text-white rounded-full text-sm font-semibold mb-1 cursor-pointer" >
                        {process.env.REACT_APP_NFT_CONTRACT.slice(0,6)}...{process.env.REACT_APP_NFT_CONTRACT.slice(36)}/{item.tokenId}
                      </span>
                      </CopyToClipboard>
                    </div>
                    <div>
                    <CopyToClipboard text={`${process.env.REACT_APP_NFT_CONTRACT}/${item.tokenId}`}
                             onCopy={() => toast.success("Copied to Clipboard")}>
                      <AiOutlineCopy className="cursor-pointer text-lg font-bold"/>
                      </CopyToClipboard>
                    </div>

                  </div>
                  
                  {
                    isOwner ? 
                    <div className="my-2">
                    <div className="m-auto flex w-full justify-center bg-[#B87333]  hover:bg-[#c07229] cursor-pointer" onClick={()=>openModel(item)}>
                      {/* <Link to={`/detail/${item.token_id}`}>  */}
                      <button
                        className="text-lg text-white font-semibold m-auto"
                      >
                        List
                      </button>
                    </div>
                  </div>
                  : ''
                  }


                  
                </div>)
                : ''
            }
          </div>

        </div>
      </div>
      <UnListedModel showModal={showModal} setShowModal={setShowModal} data={data}/>
      <Footer />
    </>
  );
};

export default List;
