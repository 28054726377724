import { createContext, useEffect, useState } from "react";
import { ethers } from 'ethers';
import axios from "axios";
import NFTABI from '../utils/NFT.json';
import MARKETPLACEABI from '../utils/MARKETPLACE.json';
import COIN from '../utils/COIN.json';
import { toast } from "react-toastify";

const InstanceContext = createContext();

//----CONTRACT LOAD CONTEXT
const InstanceProvider = ({ children }) => {
    //---USESTATES
    const [collectionInstance, setCollectionInstance] = useState([]);
    const [MarketplaceInstance, setMarketplaceInstance] = useState([]);
    const [CoinInstance, setCoinInstance] = useState([]);
    const [address, setAddress] = useState("");
    const [token, setToken] = useState("")
    const [count, setCount] = useState(0);
    const [unlisted, setUnListed] = useState([]);
    const [listed, setListed] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const [userBalance, setUserBalance] = useState("");
    const [isError, setIsError] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [isBuying, setIsBuying] = useState(false);
    const [isWithdraw, setIsWithdraw] = useState(false);
    const [isMint, setIsMint] = useState(false);
    const [ethError, setEthError] = useState(false);

    //---NETWORK RESTRICT
    // const networks = {
    //     polygon: {
    //         chainId: `0x${Number(5).toString(8)}`,
    //         chainName: "Goerli test network",
    //         nativeCurrency: {
    //             name: "Goerli test network",
    //             symbol: "GoerliETH",
    //             decimals: 18,
    //         },
    //         rpcUrls: ["https://goerli.infura.io/v3/"],
    //         blockExplorerUrls: ["https://goerli.etherscan.io"],
    //     },
    // };

    //---USEEFFECT FOR CONTRACT LOAD
    useEffect(() => {
        if (window.ethereum) {
            providerWrapper()
            connect()
            setEthError(false)
        }
        else {
            setEthError(true)
        }
    }, [])

    useEffect(() => {
        if (collectionInstance != "") {
            collectionInstance.owner().then(resp => {
                // console.log(resp)
                if (resp.toLowerCase() == address.toLowerCase()) {
                    setIsOwner(true)
                }
            }).catch(err => console.log(err))
        }
    }, [address, collectionInstance])

    if (window.ethereum) {
        window.ethereum.on('accountsChanged', function (accounts) {
            setToken("");
            setIsOwner(false);
            localStorage.removeItem("tokenId")
            window.location.reload()
        })
    }

    //--- USEEFFECT FOR COUNT CALL
    useEffect(() => {
        address && fileCount()
    }, [token, address])

    //--- SIGNER AND PROVIDER WRAPPER
    const providerWrapper = async () => {
        try {
            if (window.ethereum) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                console.log("=========>", provider.network);
                // if (provider.network !== "Goerli test network") {
                //     await window.ethereum.request({
                //         method: "wallet_switchEthereumChain",
                //         params: [{
                //             chainId: "0x5"
                //         }],
                //     });
                // }

                const signer = provider.getSigner();
                loadNFT1155(signer)
                loadMarketplace(signer)
                loadIpCoin(signer)
            }
            else {
                console.log("Please install metamask..")
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    //---load NFT1155 contract
    const loadNFT1155 = async (signer) => {
        try {
            const contract = new ethers.Contract(process.env.REACT_APP_NFT_CONTRACT, NFTABI, signer);
            setCollectionInstance(contract)
        } catch (error) {
            console.log(error.message)
        }
    }

    //---load MARKETPLACE contract
    const loadMarketplace = async (signer) => {
        try {
            const contract = new ethers.Contract(process.env.REACT_APP_MARKETPLACE_CONTRACT, MARKETPLACEABI, signer);
            setMarketplaceInstance(contract)
        } catch (error) {
            console.log(error.message)
        }
    }

    //---load IP coin
    const loadIpCoin = async (signer) => {
        try {
            const contract = new ethers.Contract(process.env.REACT_APP_COIN_CONTRACT, COIN, signer);
            setCoinInstance(contract)
            console.log(contract)
        } catch (error) {
            console.log(error.message)
        }
    }

    //---CONNECT METAMASK WALLET AND STORE ADDRESS
    const connect = async () => {
        try {
            const result = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAddress(result[0])
        } catch (error) {
            console.log(error.message)
        }
    }

    //---REGISTER USER
    const registerUser = (data) => {
        try {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, {
                ...data,
                wallet: address
            }).then(resp => {
                console.log(resp)
                if (resp['status'] === 200) {
                    localStorage.setItem("tokenId", resp.data.token);
                    setToken(resp['data']['token'])
                    setIsSuccess("Login Successfull")
                }
                else if (resp['status'] === 201) {
                    setIsError(resp['data']['message'])
                }
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error.message)
        }
    }

    //---LOGIN USER 
    const loginUser = (data) => {
        try {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
                ...data,
                wallet: address
            }).then(resp => {
                if (resp['status'] === 200) {
                    localStorage.setItem("tokenId", resp.data.token);
                    setToken(resp['data']['token'])
                    setIsSuccess("Login Successfull")
                }
                else if (resp['status'] === 201) {
                    setIsError(resp['data']['message'])
                }

            })
                .catch(err => console.log(err.message))
        } catch (error) {
            console.log(error.message)
        }
    }

    //---CREATE JSON FILE IN SERVER
    const createFile = (data) => {
        try {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/form/file/${address}`, {
                ...data
            }).then(resp => {
                console.log(resp)
                toast.success("Patent saved Successfully")
            })
                .catch(err => console.log(err))
        } catch (error) {
            console.log(error.message)
        }
    }

    //---JSON FILE COUNT 
    const fileCount = () => {
        try {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/form/count/${address}`)
                .then(resp => {
                    console.log(resp)
                    setCount(resp['data']['counter'] + 1)
                })
                .catch(err => console.log(err))
        } catch (error) {
            console.log(error.message)
        }
    }

    //---MINT FUNC 
    const mintFunc = async (metadata, qty, id) => {
        try {
            // console.log(metadata,qty,parseInt(id._hex,16))
            const resp = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/collection/create`, {
                wallet: address.toLocaleLowerCase(),
                name: metadata.name,
                image_url: metadata.image,
                pdf_url: metadata.pdf,
                quantity: qty,
                tokenId: parseInt(id._hex, 16)
            })

            console.log("hjdsfhjdsfdskjfns",resp)
            setIsMint(false)
        } catch (error) {
            console.log(error.message)
            setIsMint(false)
        }
    }

    //---UNLISTED NFT
    const unListedNFT = async () => {
        try {
            // const resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/collection/unlisted/0xca3c0f16045efaecf0db43a83dd51f65ffbe1321`);
            console.log("kjashfdkjf",address.toLocaleLowerCase())
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/collection/unlisted/${address.toLocaleLowerCase()}`);
            setUnListed(resp['data'])
            console.log(resp)
        } catch (error) {
            console.log(error.message)
        }
    }

    //---LIST NFT
    const ListNft = async (id, price, amount, _id) => {
        try {
            if (MarketplaceInstance != "") {
                toast.promise(
                    MarketplaceInstance.list(id, ethers.utils.parseUnits(price.toString(), "ether"), amount).then(resp => {
                        toast.promise(
                            resp.wait().then(res => {
                                axios.put(`${process.env.REACT_APP_BACKEND_URL}/collection/list/${_id}`, { price }).then((data) => {
                                    console.log(data);
                                    unListedNFT()
                                }).catch(err => console.log(err))
                            }).catch(err => console.log(err))
                            ,
                            {
                                pending: "Listing Please Wait...",
                                success: "Listed Successfully",
                                error: "Something went wrong!",
                            }
                        )
                    }).catch(err => console.log(err))
                    ,
                    {
                        pending: "Please Wait...",
                        success: "Creating transaction",
                        error: "Something went wrong!",
                    }
                )

            }
        } catch (error) {
            console.log(error.message)
        }
    }

    //---GET LISTED NFT
    const ListedNft = async () => {
        try {
            const resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/collection/listed`);
            setListed(resp['data'])
            console.log(resp)
        } catch (error) {
            console.log(error.message)
        }
    }

    //---BUY NFT
    const buyNft = async (data, quantity) => {
        console.log(data)
        try {
            if (loadIpCoin != "") {
                setIsBuying(true)
                const allowance = await CoinInstance.allowance(address, process.env.REACT_APP_MARKETPLACE_CONTRACT);
                console.log("dsfshj",ethers.BigNumber.from(allowance).toString())
                if (ethers.utils.formatEther(data.price*quantity )<= parseInt(allowance._hex, 16)) {
                    const resp = await CoinInstance.approve(process.env.REACT_APP_MARKETPLACE_CONTRACT, ((data.price * 10 ** 18)* quantity ).toString());
                    toast.promise(
                        resp.wait().then(async (res) => {
                            console.log(res)
                            const sell = await MarketplaceInstance.sell(data.tokenId, quantity);
                            toast.promise(
                                sell.wait().then(res => {
                                    console.log(res)
                                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/collection/buy`, {
                                        wallet: address,
                                        name: data.name,
                                        image_url: data.image_url,
                                        pdf_url: data.pdf_url,
                                        quantity: quantity,
                                        tokenId: data.tokenId,
                                        id: data._id
                                    }).then(resp => {
                                        console.log(resp)
                                        if (resp['status'] === 201) {
                                            setIsError(resp['data']['message'])
                                        }
                                        else if (resp['status'] === 200) {
                                            // setIsSuccess("Buy Successfull")
                                        }
                                        setIsBuying(false)
                                    }).catch(err => {
                                        console.log(err)
                                        setIsError(err.error.message)
                                        setIsBuying(false)
                                    })
                                }).catch(err => console.log(err))
                                ,
                                {
                                    pending: "Please Wait...",
                                    success: "Buy Successful",
                                    error: "Something went wrong!",
                                }
                            )
                        }).catch(err => {
                            console.log(err)
                            setIsError(err.error.message)
                            setIsBuying(false)
                        })
                        ,
                        {
                            pending: "Please Wait...",
                            success: "Creating Transaction",
                            error: "Something went wrong!",
                        }
                    )

                }
                else {
                    console.log("else sell")
                    const _sell = await MarketplaceInstance.sell(data.tokenId, quantity);
                    toast.promise(
                        _sell.wait().then(res => {
                            console.log(res)
                            toast.promise(
                                //     console.log("sellll")
                                // console.log(res)
                                axios.post(`${process.env.REACT_APP_BACKEND_URL}/collection/buy`, {
                                    wallet: address,
                                    name: data.name,
                                    image_url: data.image_url,
                                    pdf_url: data.pdf_url,
                                    quantity: quantity,
                                    tokenId: data.tokenId,
                                    id: data._id
                                }).then(resp => {
                                    console.log(resp)
                                    if (resp['status'] === 201) {
                                        setIsError(resp['data']['message'])
                                    }
                                    else if (resp['status'] === 200) {
                                        // setIsSuccess("Buy Successfull")
                                    }
                                    setIsBuying(false)
                                }).catch(err => {
                                    console.log(err)
                                    setIsBuying(false)
                                    setIsError(err.error.message)
                                })
                                ,
                                {
                                    pending: "Please Wait...",
                                    success: "Buy Successfull",
                                    error: "Something went wrong!",
                                }
                            )
                        }).catch(err => {
                            console.log(err)
                            setIsError(err.error.message)
                            setIsBuying(false)
                        })
                        ,
                        {
                            pending: "Please Wait...",
                            success: "Creating Transaction",
                            error: "Something went wrong!",
                        }
                    )
                }


            }
        } catch (error) {
            console.log(error.message)
            setIsError(error.error.message)
            setIsBuying(false)
        }
    }

    //---get user ip tokens
    const getBalance = async () => {
        if (CoinInstance != "") {
            const resp = await CoinInstance.balanceOf(address)
            console.log(parseInt(resp._hex, 16) / 10 ** 18)
            setUserBalance(parseInt(resp._hex, 16) / 10 ** 18)
        }
    }

    const withdrawFunc = async (address, amount) => {
        try {
            if (MarketplaceInstance != "") {
                setIsWithdraw(true)
                const resp = await MarketplaceInstance.withdraw(address, (amount * 10 ** 18).toString());
                resp.wait().then(res => {
                    toast.success("withdraw Successful");
                    setIsWithdraw(false)
                }).catch(err => {
                    console.log(err)
                    setIsWithdraw(false)
                    toast.error(err.error.message);
                })
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <InstanceContext.Provider value={{ collectionInstance, MarketplaceInstance, address, connect, registerUser, token, setToken, loginUser, createFile, count, mintFunc, unListedNFT, unlisted, ListNft, listed, ListedNft, buyNft, isOwner, getBalance, userBalance, withdrawFunc, isError, setIsError, isSuccess, setIsSuccess, isBuying, isWithdraw, isMint, setIsMint, ethError }}>
            {children}
        </InstanceContext.Provider>
    );
}

export { InstanceContext, InstanceProvider }