import React, { useEffect, useState } from 'react'
import logo from '../assets/mini_10.png'
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { InstanceContext } from '../context/InstanceContext';
import { toast } from 'react-toastify';
import BgVideo from "../assets/bkg/abstract.mp4";

const Login = () => {
  //---USESTATE
  const [loginInput, setLoginInput] = useState({
    password: "",

  });
  
  //---USENAVIGATION
  const navigate = useNavigate();

  const FormHandler = (e) => {
    setLoginInput({
      ...loginInput,
      [e.target.name]: e.target.value,
    });
  };
  
  //---USECONTEXT
  const { connect, address, loginUser, token, isError, setIsError, isSuccess, setIsSuccess } = useContext(InstanceContext);

  useEffect(() => {
    if(isError !== "")
    {
      toast.error(isError);
      setIsError("")
    }
  }, [isError])

  useEffect(() => {
    if(isSuccess !== "")
    {
      toast.success(isSuccess)
      setIsSuccess("")
    }
  }, [isSuccess])

  //---LOGIN FUNC
  const loginFunc = () => {
    try {
      if(loginInput.password === "")
      {
        toast.error("Password is required")
      }
      else 
      {
        loginUser(loginInput)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  //---USEEFFECT
  useEffect(() => {
    if (token != "") {
      navigate('/')
    }
  }, [token])

  return (
    <>
      <Navbar />
      <div className="min-h-screen py-8 ">
        <video className="mint-video-bg" src={BgVideo} autoPlay muted loop />
        <div className=''>
        <div className='flex flex-col h-screen justify-center'>
          <center>
            <img className='w-18 h-16' src={logo} />
          </center>
          <center className='text-white text-3xl pt-3 font-semibold'>
            <h1>IPCRE8</h1>
          </center>
          {/* <center className='pt-2'>
                <input className=' w-56 p-1 px-3 text-sm  text-white border-none bg-slate-700' type="text" autocomplete="off" placeholder='Username '/>
            </center> */}
          {/* <center className='text-white text-3xl pt-3 font-semibold'>
                <h3>{address}</h3>
            </center> */}
          <center className='pt-2'>
            <input className=' w-56 p-1 text-sm border-collapse px-3 text-white  bg-slate-700' type="text" value={address} disabled autocomplete="off" />
          </center>
          <center className='pt-2'>
            <input className=' w-56 p-1 text-sm border-collapse px-3 text-white  bg-slate-700' type="password" autocomplete="off"
              name="password"
              value={loginInput.password}
              onChange={FormHandler} placeholder='Password ' />
          </center>
          <center className='pt-2'>
            {
              address ?
                <input className='w-56 p-1 font-semibold  text-lg hover:bg-[#660000] text-white bg-[#660000] cursor-pointer' type="submit" onClick={loginFunc}  value='Login' />
                :
                <input className='w-56 p-1 font-semibold  text-lg hover:bg-[#660000] text-white bg-[#660000] cursor-pointer' type="submit" onClick={() => connect()} value='Connect To Wallet' />

            }
          </center>

          <center className="pt-2 text-white ">
            <Link to='/register'>Register Now</Link>
          </center>
        </div>
      </div>
      </div>
      
      <Footer />
    </>
  )
}

export default Login